@media (min-width: 1266px){
.css-10asy0s-MuiContainer-root {
    max-width: 1450px;
}
}
.css-nrdh3j-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-input {

    min-width: 120px;
}
/* .css-1d7ot2p-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 30px;
} */
.css-1xedtnc-MuiStack-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 280px;
}

@media only screen and (max-width: 600px) {
    .css-vt1jju-MuiStack-root {
        display: block;
        text-align: center;
    }
    .css-nen11g-MuiStack-root {
        margin-top: 10px;
        display: block;
    }
    .css-un3wfh-MuiInputBase-root-MuiOutlinedInput-root {
        margin-top: 10px;

    }
}